import http from '../clients/Axios'

export default class SubDepartemenService {
  async paginate(
    pageNumber,
    pageSize = 10,
    sortField,
    sortOrder,
    dept_id = 0,
    filters
  ) {
    pageNumber = pageNumber === undefined ? 1 : pageNumber
    const sort = sortOrder === 1 ? '-' + sortField : sortField
    let filters_param = `page=${pageNumber}&size=${pageSize}&sort=${sort}`
    if (dept_id > 0) {
      filters_param = filters_param + '&dept_id=' + dept_id
    }

    if (filters) {
      const keys = Object.keys(filters)

      keys.forEach((el) => {
        if (filters[el].value) {
          if (el === 'sub_dept' || el === 'name') {
            filters_param = filters_param + '&' + el + '=' + filters[el].value.trim()
          } else {
            filters_param = filters_param + '&' + el + '=' + filters[el].value
          }
        }
      })
    }

    const res = await http.get(`/sub_department/paginate?${filters_param}`)

    return res.data
  }

  async get() {
    const res = await http.get('/sub_department?sort=name')
    return res.data
  }

  async getBy(param) {
    param = param ? param : ''
    const res = await http.get(`/sub_department${param}`)
    return res
  }

  async getByDept(div_id, dept_id) {
    let param = '?sort=name'
    if (div_id != 0) {
      param = param + `&div_id=${div_id}`
    }
    if (dept_id != 0) {
      param = param + `&dept_id=${dept_id}`
    }
    const res = await http.get(`/sub_department${param}`)
    return res.data
  }

  async add(form) {
    const res = await http.post('/sub_department', form)
    return res
  }

  async update(id, form) {
    const res = await http.put(`/sub_department/${id}`, form)
    return res
  }

  async delete(id) {
    const res = await http.delete(`/sub_department/${id}`)
    return res
  }
}
