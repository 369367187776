<template>
  <div>
    <div class="mb-4 flex justify-content-between align-items-center">
      <span class="pr-2 mr-2 font-bold text-2xl">Kategori</span>
      <div>
        <i class="pi pi-home mr-2"></i>
        <span class="font-small">/ Master / Data Kategori</span>
      </div>
    </div>
    <div class="card bg-white">
      <div class="flex justify-content-end mb-2">
        <Button icon="pi pi-plus" label="Kategori" @click="onAddData" />
      </div>
      <fx-table
        :items="items"
        :loading="isLoading"
        :total="totalRecords"
        :grid-options="options"
        :filters="filters"
        @request="onRequestData"
      >
        <template #columns>
          <Column
            field="divisi"
            header="Divisi"
            style="min-width: 12rem"
            filterField="div_id"
            :showFilterMenu="false"
          >
            <template #filter="{ filterModel, filterCallback }">
              <Dropdown
                v-model="filterModel.value"
                @change="filterCallback"
                :options="list_divisi"
                :filter="true"
                optionValue="id"
                optionLabel="name"
                placeholder="Pilih Divisi"
                class="p-column-filter"
                :showClear="true"
              />
            </template>
          </Column>
          <Column
            field="department"
            header="Departemen"
            filterField="dept_id"
            style="min-width: 12rem"
            :showFilterMenu="false"
          >
            <template #filter="{ filterModel, filterCallback }">
              <Dropdown
                v-model="filterModel.value"
                @change="filterCallback()"
                :options="list_departemen"
                :filter="true"
                optionValue="id"
                optionLabel="name"
                placeholder="Pilih Departemen"
                class="p-column-filter"
                :showClear="true"
              >
              </Dropdown>
            </template>
          </Column>
          <Column
            field="sub_department"
            filterField="subdept_id"
            header="Sub Departemen"
            style="min-width: 12rem"
            :showFilterMenu="false"
          >
            <template #filter="{ filterModel, filterCallback }">
              <Dropdown
                v-model="filterModel.value"
                @change="filterCallback()"
                :options="list_subdepartemen"
                :filter="true"
                optionValue="id"
                optionLabel="name"
                placeholder="Pilih Sub Departemen"
                class="p-column-filter"
                :showClear="true"
              >
              </Dropdown>
            </template>
          </Column>
          <Column
            field="ctg"
            header="Kode Kategori"
            style="min-width: 12rem"
            sortable
            :filter-match-mode-options="equalModesOption"
          >
            <template #filter="{ filterModel, filterCallback }">
              <InputText
                v-model="filterModel.value"
                type="text"
                class="p-column-filter"
                placeholder="Search"
                @keydown.enter="filterCallback()"
              />
            </template>
          </Column>
          <Column
            field="name"
            header="Nama Kategori"
            style="min-width: 15rem"
            sortable
            :filter-match-mode-options="containModesOption"
          >
            <template #filter="{ filterModel, filterCallback }">
              <InputText
                v-model="filterModel.value"
                type="text"
                class="p-column-filter"
                placeholder="Search"
                @keydown.enter="filterCallback()"
              />
            </template>
          </Column>
          <Column style="flex-grow: 1; justify-content: flex-end">
            <template #body="{ data }">
              <Button
                type="button"
                icon="pi pi-pencil"
                class="p-button-outlined p-button-secondary p-button-text mr-2"
                @click="onEditData(data)"
              />
              <Button
                type="button"
                icon="pi pi-trash"
                class="p-button-outlined p-button-danger p-button-text"
                @click="onConfirmDeletion(data)"
              />
            </template>
          </Column>
        </template>
      </fx-table>
    </div>
    <Dialog
      :header="form && form.id > 0 ? 'Edit kategori' : 'Tambah kategori'"
      v-model:visible="dialog"
      :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
      :style="{ width: '40vw' }"
      :modal="true"
      :maximizable="true"
    >
      <form-kategori
        :item="form"
        :loading="isLoadingSave"
        :list-divisi="list_divisi"
        :editMode="editMode"
        @save="onSaveData"
      />
    </Dialog>
    <Dialog
      header="Hapus Kategori"
      v-model:visible="dialogHapus"
      :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
      :style="{ width: '50vw' }"
      :modal="true"
    >
      <div class="confirmation-content">
        <span
          >Kategori <strong>{{ form.nama }}</strong> akan dihapus. Proses
          ?</span
        >
      </div>
      <template #footer>
        <Button
          label="Hapus"
          icon="pi pi-trash"
          @click="onDeleteKategori"
          class="p-button-text p-button-danger"
        />
      </template>
    </Dialog>
    <hotkey :shortcuts="['D']" @triggered="onTriggerHotkey" />
  </div>
</template>

<script>
import DivisiService from '@/services/DivisiService'
import DepartemenService from '@/services/DepartemenService'
import SubDepartemenService from '@/services/SubDepartemenService'
import KategoriService from '@/services/KategoriService'
import FormKategori from '@/components/master/FormKategori'
import FxTable from '@/components/FxTable'
import errorHandler from '@/helpers/error-handler'
import Hotkey from '@/components/Hotkey'
import { FilterMatchMode } from 'primevue/api'

export default {
  name: 'Kategori',
  components: {
    FormKategori,
    FxTable,
    Hotkey,
  },
  data() {
    return {
      subdept_id: 0,
      dept_id: 0,
      div_id: 0,
      divisiService: null,
      departemenService: null,
      subdepartemenService: null,
      kategoriService: null,
      dialog: false,
      dialogHapus: false,
      isLoading: false,
      isLoadingSave: false,
      editMode: false,
      list_divisi: [],
      list_departemen: [],
      list_subdepartemen: [],
      items: [],
      item: {},
      form: {
        id: 0,
        div_id: 0,
        dept_id: 0,
        subdept_id: 0,
        div: '',
        dept: '',
        name: null,
        ctg: null,
      },
      kategori: [],
      totalRecords: 0,
      options: {
        page: 1,
        rows: 10,
        sortField: 'divisi,department,sub_department,ctg',
        filters: {},
      },
      filters: {
        div_id: { value: null, matchMode: FilterMatchMode.EQUALS },
        dept_id: { value: null, matchMode: FilterMatchMode.EQUALS },
        subdept_id: { value: null, matchMode: FilterMatchMode.EQUALS },
        ctg: { value: null, matchMode: FilterMatchMode.CONTAINS },
        name: { value: null, matchMode: FilterMatchMode.CONTAINS },
      },
      containModesOption: [
        { label: 'Contains', value: FilterMatchMode.CONTAINS },
      ],
      equalModesOption: [{ label: 'Equals', value: FilterMatchMode.EQUALS }],
    }
  },
  created() {
    this.divisiService = new DivisiService()
    this.departemenService = new DepartemenService()
    this.subdepartemenService = new SubDepartemenService()
    this.kategoriService = new KategoriService()
  },
  async mounted() {
    const divisiService = new DivisiService()
    await divisiService
      .get()
      .then((res) => {
        this.list_divisi = res.data
      })
      .catch((err) => {
        errorHandler(err, 'Data Divisi', this)
      })

    this.loadData()
  },
  methods: {
    onRequestData(e) {
      this.options = e
      this.loadData()
    },
    loadData() {
      this.isLoading = true
      const page = this.options.page
      const perPage = this.options.rows
      const sortField = this.options.sortField
      const sortOrder = this.options.sortOrder
      const filters = this.options.filters

      if (this.options.filters['div_id'] == null) {
        this.div_id = 0
      } else {
        if (this.div_id != this.options.filters['div_id'].value) {
          this.div_id = this.options.filters['div_id'].value
          this.dept_id = 0
        }
      }
      this.onLoadDataDepartemen()

      if (this.options.filters['dept_id'] == null) {
        this.dept_id = 0
      } else {
        if (this.dept_id != this.options.filters['dept_id'].value) {
          this.dept_id = this.options.filters['dept_id'].value
          this.subdept_id = 0
        }
      }
      this.onLoadDataSubDepartemen()

      this.kategoriService
        .paginate(
          page,
          perPage,
          sortField,
          sortOrder,
          this.div_id,
          this.dept_id,
          filters
        )
        .then((res) => {
          this.items = res.data
          this.totalRecords = res.total
          this.isLoading = false
        })
        .catch((err) => {
          errorHandler(err, 'Data Kategori', this)
        })
        .finally(() => (this.isLoading = false))
    },
    onLoadDataDepartemen() {
      this.departemenService
        .getByDiv(this.div_id)
        .then((res) => {
          this.list_departemen = res.data
          this.isLoading = false
        })
        .catch((err) => {
          errorHandler(err, 'Data departemen', this)
        })
        .finally(() => (this.isLoading = false))
    },
    onLoadDataSubDepartemen() {
      if (this.div_id == null) {
        this.div_id = 0
      }
      if (this.dept_id == null) {
        this.dept_id = 0
      }
      this.subdepartemenService
        .getByDept(this.div_id, this.dept_id)
        .then((res) => {
          this.list_subdepartemen = res.data
          this.isLoading = false
        })
        .catch((err) => {
          errorHandler(err, 'Data sub departemen', this)
        })
        .finally(() => (this.isLoading = false))
    },
    onEditData(data) {
      this.editMode = true
      this.form.id = data.id
      this.form.div_id = data.div_id
      this.form.dept_id = data.dept_id
      this.form.subdept_id = data.subdept_id
      this.form.ctg = data.ctg
      this.form.name = data.name
      this.form.ctg = data.ctg
      this.dialog = true
    },
    onAddData() {
      this.editMode = false
      this.form.div_id = null
      this.form.dept_id = null
      this.form.subdept_id = null
      this.form.ctg = ''
      this.form.name = null
      this.dialog = true
    },
    onSaveData(data) {
      this.isLoadingSave = true
      let item = {}
      item.subdept_id = data.subdept_id
      item.ctg = data.ctg
      item.name = data.name
      if (data.id == 0) {
        this.kategoriService
          .add(item)
          .then((res) => {
            if (res.data.status === 1) {
              this.loadData()
              this.$toast.add({
                severity: 'success',
                summary: 'Data kategori',
                detail: 'Data berhasil disimpan.',
                life: 3000,
              })
              this.dialog = false
            }
          })
          .catch((err) => {
            errorHandler(err, 'Data kategori', this)
          })
          .finally(() => (this.isLoadingSave = false))
      } else {
        this.kategoriService
          .update(data.id, item)
          .then((res) => {
            if (res.data.status === 1) {
              this.loadData()
              this.$toast.add({
                severity: 'success',
                summary: 'Data kategori',
                detail: 'Data berhasil disimpan.',
                life: 3000,
              })
              this.dialog = false
            }
          })
          .catch((err) => {
            errorHandler(err, 'Data kategori', this)
          })
          .finally(() => (this.isLoadingSave = false))
      }
    },
    onConfirmDeletion(data) {
      this.form = data
      this.dialogHapus = true
    },
    onDeleteKategori() {
      this.kategoriService
        .delete(this.form.id)
        .then((res) => {
          if (res.status === 1) {
            this.$toast.add({
              severity: 'success',
              summary: 'Data Kategori',
              detail: 'Data berhasil dihapus.',
              life: 3000,
            })
          }
          this.loadData()
        })
        .catch((err) => {
          errorHandler(err, 'Data Kategori', this)
        })
      this.form = {}
      this.dialogHapus = false
    },
    onTriggerHotkey(payload) {
      if (payload.keyString === 'D') {
        this.onAddData()
      }
    },
    /* onToggle(value) {
      this.selectedColumns = this.columns.filter((col) => value.includes(col))
    }, */
  },
}
</script>
