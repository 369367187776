<template>
  <div>
    <form @submit.prevent="handleSubmit(!v$.$invalid)">
      <div class="formgrid grid">
        <div class="field col-12 md:col-6">
          <label>Divisi</label>
          <Dropdown
            v-model="form.div_id"
            :filter="true"
            :options="listDivisi"
            class="w-full"
            :class="{ 'p-invalid': v$.form.div_id.$invalid && submitted }"
            input-class="w-full"
            optionValue="id"
            optionLabel="name"
            @change="LoadDataDepartemen"
            @filter="onFilterDivisi"
          />
          <small
            v-if="
              (v$.form.div_id.$invalid && submitted) ||
              v$.form.div_id.$pending.$response
            "
            class="p-error"
            >{{ v$.form.div_id.required.$message }}</small
          >
        </div>
        <div class="field col-12 md:col-6">
          <label>Departemen</label>
          <Dropdown
            v-model="form.dept_id"
            :filter="false"
            :options="listDepartemen"
            class="w-full"
            :class="{ 'p-invalid': v$.form.dept_id.$invalid && submitted }"
            input-class="w-full"
            optionValue="id"
            optionLabel="name"
            @change="LoadDataSubDepartemen"
            @filter="onFilterDepartemen"
          />
          <small
            v-if="
              (v$.form.dept_id.$invalid && submitted) ||
              v$.form.dept_id.$pending.$response
            "
            class="p-error"
            >{{ v$.form.dept_id.required.$message }}</small
          >
        </div>
        <div class="field col-12 md:col-6">
          <label>Sub Departemen</label>
          <Dropdown
            v-model="form.subdept_id"
            :filter="false"
            :options="listSubDepartemen"
            class="w-full"
            :class="{ 'p-invalid': v$.form.subdept_id.$invalid && submitted }"
            input-class="w-full"
            optionValue="id"
            optionLabel="name"
            @filter="onFilterSubDepartemen"
          />
          <small
            v-if="
              (v$.form.subdept_id.$invalid && submitted) ||
              v$.form.subdept_id.$pending.$response
            "
            class="p-error"
            >{{ v$.form.subdept_id.required.$message }}</small
          >
        </div>
        <div class="field col-12 md:col-6">
          <label>Kode Kategori (Opsional)</label>
          <InputText
            v-model="form.ctg"
            type="text"
            class="w-full"
            maxlength="4"
          />
          <small
            v-if="
              this.editMode == true &&
              (this.form.ctg == '' || this.form.ctg == null)
            "
            class="p-error"
            >Kode kategori tidak boleh kosong</small
          >
        </div>

        <div class="field col-12 md:col-12">
          <label :class="{ 'p-error': v$.form.name.$invalid && submitted }"
            >Nama Kategori</label
          >
          <InputText
            v-model="form.name"
            :class="{ 'p-invalid': v$.form.name.$invalid && submitted }"
            type="text"
            class="w-full"
            maxlength="40"
          />
          <small
            v-if="
              (v$.form.name.$invalid && submitted) ||
              v$.form.name.$pending.$response
            "
            class="p-error"
            >{{ v$.form.name.required.$message }}</small
          >
        </div>
      </div>
      <div class="flex justify-content-end align-items-end">
        <Button
          :loading="loading"
          label="Simpan"
          icon="pi pi-check"
          type="submit"
        />
      </div>
    </form>
  </div>
</template>

<script>
import useVuelidate from '@vuelidate/core'
import errorHandler from '@/helpers/error-handler'
import DepartemenService from '@/services/DepartemenService'
import SubDepartemenService from '@/services/SubDepartemenService'
import { helpers, required } from '@vuelidate/validators'

export default {
  setup: () => ({ v$: useVuelidate() }),
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    editMode: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
      default() {
        return {}
      },
    },
    listDivisi: {
      type: Array,
      default() {
        return []
      },
    },
  },
  created() {
    this.departemenService = new DepartemenService()
    this.subdepartemenService = new SubDepartemenService()
  },
  async mounted() {
    this.listDepartemen = []
    this.listSubDepartemen = []
    if (this.editMode == true) {
      this.LoadDataDepartemen()
      this.LoadDataSubDepartemen()
    }
  },
  data() {
    return {
      submitted: false,
      departemenService: null,
      subdepartemenService: null,
      listDepartemen: [],
      listSubDepartemen: [],
    }
  },
  computed: {
    form() {
      return this.item
    },
  },
  methods: {
    handleSubmit(isFormValid) {
      this.submitted = true

      if (
        this.editMode == true &&
        (this.form.ctg == '' || this.form.ctg == null)
      ) {
        return
      }

      if (!isFormValid) {
        return
      }

      this.$emit('save', this.form)
    },
    onFilterDivisi() {
      this.form.div_id = null
      this.form.dept_id = null
      this.form.subdept_id = null
    },
    onFilterDepartemen() {
      this.form.dept_id = null
      this.form.subdept_id = null
    },
    onFilterSubDepartemen() {
      this.form.subdept_id = null
    },
    LoadDataDepartemen() {
      if (this.form.div_id == null) {
        this.form.dept_id = null
        this.form.subdept_id = null
      }
      this.departemenService
        .getByDiv(this.form.div_id)
        .then((res) => {
          this.listDepartemen = res.data
          this.isLoading = false
        })
        .catch((err) => {
          errorHandler(err, 'Data departemen', this)
        })
        .finally(() => (this.isLoading = false))
    },
    LoadDataSubDepartemen() {
      if (this.form.dept_id == null) {
        this.form.subdept_id = null
      }
      this.subdepartemenService
        .getByDept(this.form.div_id, this.form.dept_id)
        .then((res) => {
          this.listSubDepartemen = res.data
          this.isLoading = false
        })
        .catch((err) => {
          errorHandler(err, 'Data sub departemen', this)
        })
        .finally(() => (this.isLoading = false))
    },
  },
  validations() {
    return {
      form: {
        div_id: {
          required: helpers.withMessage('Divisi harus dipilih.', required),
        },
        dept_id: {
          required: helpers.withMessage('Departemen harus dipilih.', required),
        },
        subdept_id: {
          required: helpers.withMessage(
            'Sub Departemen harus dipilih.',
            required
          ),
        },
        name: {
          required: helpers.withMessage('Nama kategori harus diisi.', required),
        },
      },
    }
  },
}
</script>
